/**
 * Handles API authentication, API calls and media from the ogor backend.
 */

import EventBus from "./event-bus";
import { downloadFile, getCookie, logError } from "./util";
import { apiBase, apiURL, wsBase } from "./constants";
import contentDisposition from "content-disposition";
import mimeTypes from "@/lib/mimeTypes";
import { Raster } from "./types/Raster";
import { Paginated } from "./types/Paginated";
import { AlcedoOrder } from "./types/AlcedoOrder";
import { Parcel } from "./types/Parcel";
import { Activity, CropActivity, CropPlan } from "./types/Plan";

class APIError extends Error {
  constructor(message: string, details: any) {
    message = `${message}: ${details.detail}`;
    super(message);
    Object.assign(this, details);
    this.name = "APIError";
  }
}

/**
 * Calls the apiCall function, but enables the browser cache.
 */
export async function cachedApiCall<T>(
  method = "GET",
  endpoint = "/",
  params = {},
  data = undefined,
  contentType = "application/json"
) {
  return apiCall<T>(method, endpoint, params, data, contentType, "default");
}

/**
 * Calls the apiCall function, but downloads the response instead of returning it.
 */
export async function downloadApiCall(
  method = "GET",
  endpoint = "/",
  params = {},
  data = undefined,
  contentType = "application/json",
  accept = "application/json"
) {
  return apiCall(
    method,
    endpoint,
    params,
    data,
    contentType,
    "no-cache",
    true,
    accept
  );
}

/**
 * Resolve a promise and ignored all API errors or only the specified ones.
 *
 * @param promise {Promise}
 * @param returnValue what should be returned instead of the error
 * @param ignoredErrors list of error codes to ignore, if not given
 *  all errors are ignored instead
 */
export async function ignoreAPIError<T> (
  promise: Promise<T>,
  returnValue: T = null,
  ignoredErrors: number[]
): Promise<T> {
  try {
    return await promise;
  } catch (e) {
    if (!ignoredErrors || ignoredErrors.indexOf(e.statusCode) !== -1) {
      return returnValue;
    }
    throw e;
  }
}

/**
 * Perform an API call to the backend.
 *
 * @param method HTTP method to use
 * @param endpoint API endpoint or full URL.
 * @param params Object with URL parameters. Alternatively an Array can
 *  be passed as parameters with pairs of (key, value). This can be used
 *  when repeating query args needs to be passed to the API.
 * @param data request body
 * @param contentType specify the request body content type
 * @param cacheMode value for Request.cache
 * @param download
 * @param accept Accept header
 */
export async function apiCall<T> (
  method = "GET",
  endpoint = "/",
  params: [string, any][] | { [param: string]: any } = {},
  data = undefined,
  contentType = "application/json",
  cacheMode: RequestCache = "no-cache",
  download = false,
  accept = "application/json"
): Promise<T> {
  let url: URL;
  if (!endpoint) return;

  if (endpoint.startsWith(apiURL)) {
    url = new URL(endpoint);
  } else {
    url = new URL(apiURL + endpoint);
  }

  if (!Array.isArray(params)) {
    params = Object.entries(params);
  }
  params.forEach((entry) => {
    url.searchParams.append(entry[0], entry[1]);
  });

  let preferredLanguage: string;
  try {
    preferredLanguage = window.localStorage.getItem("language");
  } catch (e) {
    // User doesn't seem to allow storage
  }
  if (!preferredLanguage) {
    preferredLanguage = window.currentLanguage;
  }

  const csrfToken = getCookie("csrftoken");
  const fetchOptions: RequestInit = {
    method: method,
    mode: "cors",
    cache: cacheMode,
    credentials: "include",
    headers: {
      Accept: accept,
    },
    redirect: "follow",
    body: undefined,
    // Microsoft Edge is sadness
    // referrerPolicy: "strict-origin",
  };
  if (data instanceof FormData) {
    contentType = "multipart/form-data"
  }

  if (data) {
    switch (contentType) {
      case "multipart/form-data":
        if (data instanceof FormData) {
          fetchOptions.body = data
          break
        }
        const formData = new FormData();
        Object.entries(data as Record<string, any>).forEach((value) => {
          formData.append(...value);
        });
        fetchOptions.body = formData;
        break;
      case "application/json":
        fetchOptions.headers["Content-Type"] = contentType;
        fetchOptions.body = JSON.stringify(data);
        break;
      default:
        fetchOptions.headers["Content-Type"] = contentType;
        fetchOptions.body = data;
    }
  }
  if (csrfToken) {
    fetchOptions.headers["X-CSRFToken"] = csrfToken;
  }
  if (preferredLanguage) {
    fetchOptions.headers["Accept-Language"] = preferredLanguage;
  }

  try {
    EventBus.$emit(EventBus.events.requestStarted);
    return await _apiCall(url, fetchOptions, download);
  } finally {
    EventBus.$emit(EventBus.events.requestFinished);
  }
}

/***
 * Performs the actual api call and does error handling.
 *
 * @param url
 * @param fetchOptions
 * @param download
 * @private
 */
async function _apiCall(url: URL, fetchOptions: RequestInit, download = false) {
  let response: Response;
  try {
    response = await fetch(url.toString(), fetchOptions);
  } catch (connectError) {
    EventBus.$emit(EventBus.events.apiConnectError);
    throw connectError;
  }

  if (!download) {
    return await _processJSONResponse(response);
  }
  return await _processRawResponse(response);
}

async function _processJSONResponse(response: Response) {
  if (response.status === 204) {
    // 204 (No Content)
    return {};
  }

  // We don't know what to do with anything other than a JSON
  // so just quit here and don't read the body.
  // L.E.: we do have empty responses like when you open an CropPlanActivity
  if (response.headers.get("Content-Type") !== "application/json") {
    return
    EventBus.$emit(EventBus.events.apiUnknownError);
    throw new APIError("Unknown API error", {
      requestURL: response.url,
      statusCode: response.status,
      detail: response.statusText,
    });
  }

  let jsonBody;
  try {
    jsonBody = await response.json();
  } catch (readBodyError) {
    EventBus.$emit(EventBus.events.apiReadError);
    throw readBodyError;
  }

  jsonBody.requestURL = new URL(response.url);
  jsonBody.statusCode = response.status;

  if (!response.ok) {
    // We got an expected error from the API, the error handling
    // is passed to the component calling the API.
    throw new APIError("API Error", jsonBody);
  }
  // Success
  return jsonBody;
}

type APIFieldReasons = {
  [field: string]: string[] | APIFieldReasons;
}

export interface APIErrorReason {
  details?: string | APIFieldReasons;
  detail?: string;
  error?: string;
}

/* Example Error from BE
const error: APIErrorReason = {
  error: "Validation Error",
  details: {
    note: {
      text: ["Cannot be null.", "Min length 3 chars."]
    },
    other_field: ["Cannot be null."]
  }
} */

export function getFirstError(reason: APIErrorReason): string {
  if (reason.details) {
    if (typeof reason.details === "string") {
      return reason.details;
    } else {
      let details = reason.details;
      let firstErrorKey = Object.keys(details)[0];
      while (typeof details[firstErrorKey][0] === "object") {
        details = details[firstErrorKey][0];
        firstErrorKey = Object.keys(details)[0];
      }
      return details[firstErrorKey][0];
    }
  } else if (reason.detail || reason.error) {
    return reason.detail || reason.error;
  } else {
    logError(reason);
    return null;
  }
}

async function _processRawResponse(response: Response) {
  let filename: string;
  const disposition = response.headers.get("Content-Disposition");
  if (disposition) {
    filename = contentDisposition.parse(disposition).parameters.filename;
  }

  if (!filename) {
    const ext = mimeTypes[response.headers.get("Content-Type")] || "";
    filename =
      response.url
        .split("/")
        .filter((p) => !!p)
        .slice(-1)[0] + ext;
  }

  return downloadFile(await response.blob(), filename);
}

/**
 * Check the user credentials and log in using the API
 *
 * @param email {string}
 * @param password {string}
 */
export async function login(email: string, password: string) {
  const response = apiCall<{ key: string }>("POST", "/auth/login/", {}, { email, password });
  window.localStorage.removeItem("cloneWarningDismiss");
  return response;
}

/**
 * Log the user out of the application.
 */
export async function logOut() {
  window.localStorage.removeItem("cloneWarningDismiss");
  await apiCall("POST", "/auth/logout/");
  window.localStorage.removeItem("_userPreferences");
  window.localStorage.removeItem("userPreferences");
}

/**
 * Take an media URL and resolve it to an absolute URL.
 */
export function apiMediaURL(mediaURL: string) {
  let url: URL;
  if (!mediaURL) return;
  if (mediaURL.startsWith(apiBase)) {
    url = new URL(mediaURL);
  } else {
    if (!mediaURL.startsWith("/")) mediaURL = "/" + mediaURL;
    url = new URL(apiBase + mediaURL);
  }
  return url.toString();
}

/**
 * Extract the image for this raster at the specified zoom level
 * and resolve it to an absolute URI.
 *
 * @param raster {Object} raster serialized from the API
 * @param zoomLevel {number}
 * @return {string|undefined}
 */
export function getRasterImage(raster: Raster, zoomLevel: string | number) {
  if (!raster || !raster.images || !zoomLevel) return;
  
  if (zoomLevel === "none") return apiMediaURL("/" + raster.images[0].url);
  const targetImage = raster.images.find(
    (image) => image.zoom === `z${zoomLevel}`
  );
  if (!targetImage) return;
  return apiMediaURL("/" + targetImage.url);
}

/**
 * Can be used for working with WebSocket using async/await,
 * ideally to be used for short lived connections.
 */
export class AsyncWebSocket<T> {
  ws: WebSocket;
  url: URL;
  messages: T[];
  maxBacklog: number = 200;

  constructor(endpoint: string, maxBacklog = 200) {
    this.ws = null;
    this.messages = [];
    this.maxBacklog = maxBacklog;
    if (endpoint.startsWith(wsBase)) {
      this.url = new URL(endpoint);
    } else {
      this.url = new URL(wsBase + endpoint);
    }
  }

  /**
   * Open the websocket, resolves when the connection is fully opened,
   * and the websocket is ready to receive messages.
   *
   * Messages that are received immediately after are stored in
   * a backlog, to be processed later if needed. The backlog has a limit
   * configurable with the maxBacklog value to prevent memory leaks.
   */
  open() {
    this.ws = new WebSocket(this.url.toString());
    // Keep message in the backlog.
    this.ws.addEventListener("message", (ev) => {
      this.messages.push(JSON.parse(ev.data));
      if (this.messages.length > this.maxBacklog) {
        this.messages.shift();
      }
    });

    return new Promise((resolve, reject) => {
      this.ws.onopen = () => resolve(this);
      this.ws.onerror = (ev) => reject(ev);
    });
  }
  /**
   * Resolves until a specific message is received. The callback is executed
   * for each message in the backlog and any upcoming message.
   *
   * @param callback executed for each message until it returns a
   *   truthful value or the timeout is reached.
   * @param timeout number of ms to wait until canceling the wait.
   */
  waitUntilMessage(callback: (message: T) => any, timeout = 60000) {
    return new Promise<T>((resolve, reject) => {
      this.ws.addEventListener("close", reject);

      let resolved = false;
      // Check the backlog
      const alreadyResolvedMessage = this.messages.find((message) =>
        callback(message)
      );
      if (alreadyResolvedMessage) {
        resolved = true;
        return resolve(alreadyResolvedMessage);
      }

      // Add the handler
      const handler = (ev: MessageEvent<string>) => {
        if (resolved) return;
        const message = JSON.parse(ev.data) as T;
        if (callback(message)) {
          resolved = true;
          this.ws.removeEventListener("message", handler);
          resolve(message);
        }
      };
      this.ws.addEventListener("message", handler);

      // Add a timeout
      window.setTimeout(() => {
        if (resolved || !this.ws) return;
        resolved = true;
        this.ws.removeEventListener("message", handler);
        reject("timeout");
      }, timeout);
    });
  }
  /**
   * Close the websocket, and remove any backlogged message.
   *
   * Should always be called to release memory and remove event
   * listeners.
   */
  close() {
    try {
      this.ws.close();
    } catch (e) {
      // pass
    } finally {
      this.messages = [];
      this.ws = null;
    }
  }
}




/**
 * @todo numbers man
 */
function processPlan(plan: CropPlan) {
  const props = [
    "target_yield",
    "sale_price",
    "total_area",
  ]

  for (let prop of props) {
    plan[prop] = plan[prop] || 0;
  }
  return plan
}

/**
 * Fetch the CropPlan (WorkSheet) for a certain crop
 */
export function fetchCropPlan(farmId: string, seasonId: string, cropId: string) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop/${cropId}/crop_plan/`
  return apiCall<CropPlan>("GET", endpoint)
    .then(async response => {
      const plan = processPlan(response)
      plan.activities = await fetchCropActivities(plan)
      
      return plan
    })
}

export function patchCropPlan(farmId: string, seasonId: string, cropPlanId: string, data) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop-plan/${cropPlanId}/`
  return apiCall<CropPlan>("PATCH", endpoint, undefined, data).then(processPlan)
}

export function fetchAvailableActivities() {
  const endpoint = "/plan/activity/"
  return apiCall<Paginated<Activity>>("GET", endpoint).then(response => response.results)
}

/**
 * @todo order should be optional; if undefined add it at the end of the list.
 */
export function addCropActivity(farmId: string, seasonId: string, cropPlanId: string, data: { activity: string, order: number }) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop-plan/${cropPlanId}/activity/`

  data.total_budget = data.total_budget || 0;

  type Response = Omit<CropActivity, "activity" | "execution_reports" | "evaluation_reports"> & { activity: string }
  return apiCall<Response>("POST", endpoint, undefined, data).then(processActivity)
}

export function patchCropActivity(plan: CropPlan, activityId: string, data: Partial<Pick<CropActivity, "interval" | "order" | "total_budget">>) {
  const endpoint = `/plan/farm/${plan.farm}/season/${plan.season}/crop-plan/${plan.id}/activity/${activityId}/`
  return apiCall<CropActivity>("PATCH", endpoint, undefined, data).then(processActivity)
}

export function removeActivity(farmId: string, seasonId: string, cropPlanId: string, activityId: string) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop-plan/${cropPlanId}/activity/${activityId}/`
  return apiCall("DELETE", endpoint)
}

function processActivity(activity: CropActivity) {
  const props = [
    "worked_area",
    "total_area",
    "total_budget",
  ]

  for (let prop of props) {
    activity[prop] = activity[prop] || 0;
  }

  return activity
}

export function fetchCropActivities(plan: CropPlan) {
  const { farm, season, id } = plan
  const endpoint = `/plan/farm/${farm}/season/${season}/crop-plan/${id}/activities/?include_reports=true`
  return apiCall<CropActivity[]>("GET", endpoint)
    .then(activities => activities.map(activity => {
      activity = processActivity(activity)

      activity.execution_reports = activity.execution_reports || [];
      activity.execution_reports = activity.execution_reports.map(report => {
        report.worked_area = report.worked_area || 0;

        if (typeof report.worked_area === "string") {
          // @todo this should return ha?
          report.worked_area = parseFloat(report.worked_area) / 10000
        }
        return report
      })
      return activity
    }))
}

export function closeCropActivity(plan: CropPlan, activity: CropActivity, data: { closed_as_partial: boolean }) {
  const { farm, season } = plan
  const endpoint = `/plan/farm/${farm}/season/${season}/crop-plan/${plan.id}/activity/${activity.id}/close/`
  return apiCall("PUT", endpoint, undefined, data)
}

export function openCropActivity(plan: CropPlan, activity: CropActivity) {
  const { farm, season } = plan
  const endpoint = `/plan/farm/${farm}/season/${season}/crop-plan/${plan.id}/activity/${activity.id}/open/`
  return apiCall("PUT", endpoint)
}

export function fetchCropActivity(activityId: string) {

}

export function fetchCropActivityParcels(farmId: string, seasonId: string, cropPlanId: string, activityId: string) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop-plan/${cropPlanId}/activity/${activityId}/parcels/`

  type Response = Parcel & { worked_area: number };
  return apiCall<Response[]>("GET", endpoint).then(response => response.map(parcel => {
    parcel.localityId = parcel.locality?.id || parcel.localityId as any;
    parcel.locality = parcel.locality?.name || parcel.locality as any;
    parcel.reference = [parcel.siruta, parcel.physical_block, parcel.parcel_no, parcel.crop_no].join("-");
    parcel.name = `${parcel.locality || ""} ${parcel.physical_block || ""}-${parcel.parcel_no || ""}`;
    parcel.name = parcel.name.trim();
    parcel.nickname = parcel.display_name;
    parcel.uniqueKey = parcel.id;
    if (typeof parcel.worked_area === "string") {
      parcel.worked_area = parseFloat(parcel.worked_area)
    }
    if (parcel.worked_area === null) {
      parcel.worked_area = 0
    }
    return parcel
  }))
}

export function closeCropPlan(farmId: string, seasonId: string, cropPlanId: string, data: Object) {
  const endpoint = `/plan/farm/${farmId}/season/${seasonId}/crop-plan/${cropPlanId}/close/`
  return apiCall<Exclude<CropPlan, "activities">>("PUT", endpoint)
}

export function fetchAlcedoOrders(farmId: string) {
  const endpoint = `/farm/${farmId}/alcedo-order/`
  
  return apiCall<Paginated<AlcedoOrder>>("GET", endpoint).then(response => {
    return response.results.sort((a, b) => {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    })
  })
}